html,

body{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  /* background-color: rgb(245, 245, 245); */
  background-color: #efeff6;
}

.container{
  margin: 80px auto 0 auto;
  max-width: 1200px;
}

.nav-container{
  margin: auto;
}

a{
  text-decoration: none;
}

.nav-container svg{
  color: #fff;
}